(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form-validation/assets/javascripts/form-validation.js') >= 0) return;  svs.modules.push('/components/components/form-validation/assets/javascripts/form-validation.js');

'use strict';

svs.components = svs.components || {};

svs.components.formValidation = (function() {
  function resetError(element, placementSelector) {
    element
      .parents('.control-group')
      .removeClass('error')
      .removeClass('success');
    element.removeClass('error'); 

    $(placementSelector)
      .parents('.control-group')
      .removeClass('error');
    $(placementSelector).removeClass('error');
  }

  function hasValidStartAndEndTime(limitStartTime, limitEndTime) {
    const [startHours, startMinutes] = limitStartTime.split(':').map(Number);
    const [endHours, endMinutes] = limitEndTime.split(':').map(Number);

    if (startHours > endHours || startHours === endHours && startMinutes >= endMinutes) {
      return false;
    }

    return true;
  }

  function isStartDateBeforeEndDate(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split('-').map(Number);
    const [endYear, endMonth, endDay] = endDate.split('-').map(Number);

    if (startYear < endYear) {
      return true;
    } else if (startYear === endYear) {
      if (startMonth < endMonth) {
        return true;
      } else if (startMonth === endMonth) {
        return startDay < endDay;
      }
    }
    return false;
  }

  function hasValidStartAndEndPeriod(startDate, endDate, startTime, endTime) {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    if (!isStartDateBeforeEndDate(startDate, endDate)) {
      if ((startDate === endDate) && (startHours < endHours || (startHours === endHours && startMinutes <= endMinutes))) {
        return true;
      }
      return false;
    }

    return true;
  }

  function showError(element, errorMessage, placementSelector, onlyParent) {
    if (onlyParent) {
      element
        .closest('.control-group')
        .removeClass('success')
        .addClass('error');
      element.addClass('error'); 
    } else {
      element
        .parents('.control-group')
        .removeClass('success')
        .addClass('error');
      element.addClass('error'); 
    }

    if (errorMessage) {
      if (onlyParent) {
        element.closest('.control-group')
          .find('.error-message')
          .text(errorMessage);
        element.closest('.control-group').addClass('error');
      } else if (placementSelector) {
        $(placementSelector)
          .parents('.control-group')
          .find('.error-message')
          .text(errorMessage);
        $(placementSelector)
          .parents('.control-group')
          .addClass('error');
      } else {
        element
          .parents('.control-group')
          .find('.error-message')
          .text(errorMessage);
        element.parents('.control-group').addClass('error');
      }
    }
  }

  function showSuccess(element) {
    resetError(element);
    element.parent('.control-group').addClass('success');
  }

  return {
    validateOnBlur: svs.core.data.capabilities.formFactor === 'mobile',
    validateSsn: function(ssn) {
      return this.ssnValidation(/^(\d{2})(\d{2})(\d{2})(\d{4})$/, ssn);
    },
    validateSsn10or12: function(ssn) {
      return this.ssnValidation(
        /^(?:\d{2})?(\d{2})(\d{2})(\d{2})(\d{4})$/,
        ssn
      );
    },
    validateBankIdSsn: function(ssn) {
      return this.ssnValidation(/^\d{2}(\d{2})(\d{2})(\d{2})-(\d{4})$/, ssn);
    },
    ssnValidation: function(pnrReg, ssn) {
      var isValid = false;
      var pnr = { valid: false };

      if (pnrReg.test(ssn)) {
        isValid = true;
      }

      if (isValid) {
        pnr.now = new Date();
        pnr.nowFullYear = String(pnr.now.getFullYear());
        pnr.nowCentury = pnr.nowFullYear.substring(0, 2);
        pnr.nowShortYear = pnr.nowFullYear.substring(2, 4);
        pnr.year = RegExp.$1;
        pnr.month = RegExp.$2;
        pnr.day = RegExp.$3;
        pnr.controldigits = RegExp.$4;
        pnr.fullYear =
          Number(pnr.year) <= Number(pnr.nowShortYear) ?
            (pnr.nowCentury + pnr.year) * 1 :
            (Number(pnr.nowCentury) - 1 + pnr.year) * 1;
        var months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (
          pnr.fullYear % 400 === 0 ||
          pnr.fullYear % 4 === 0 && pnr.fullYear % 100 !== 0
        ) {
          months[1] = 29;
        }
        if (
          Number(pnr.month) < 1 ||
          Number(pnr.month) > 12 ||
          Number(pnr.day) < 1 ||
          Number(pnr.day) > months[Number(pnr.month) - 1]
        ) {
          return false;
        }

        pnr.alldigits = pnr.year + pnr.month + pnr.day + pnr.controldigits;
        var nn = '';
        for (var n = 0; n < pnr.alldigits.length; n++) {
          nn += ((n + 1) % 2 + 1) * pnr.alldigits.substring(n, n + 1);
        }
        pnr.checksum = 0;
        for (var m = 0; m < nn.length; m++) {
          pnr.checksum += Number(nn.substring(m, m + 1));
        }
        pnr.valid = pnr.checksum % 10 === 0;
      }

      return pnr.valid;
    },

    validateHasChars: function(str) {
      return str.length > 1;
    },

    validateEmail: function(email) {
      if (!email.length || email.length > 200) {
        return false;
      }

      var emailReg = /^([\w.%+-]+@([\w-]+\.)+[\w]{2,10})?$/;

      return emailReg.test(email);
    },

    validateEmailLength: function(email) {
      return !email.length || email.length < 200;
    },

    validateUsername: function(username) {
      var userNameReg = /^((?=.*[a-zA-Z])(?:[0-9a-zA-Z.@\-_]){6,30})$/;

      return userNameReg.test(username);
    },
    validateAlias: function(alias) {
      if (alias.trim().length < alias.length) {
        return false;
      }
      var aliasReg = /^((?=.*[A-Za-zåäöÅÄÖéÉüÜ()+ ._?=!§$£@{}+-])(?:[0-9A-Za-zåäöÅÄÖéÉüÜ()+ ._?=!§$£@{}+-]){2,10})$/;

      return aliasReg.test(alias);
    },

    validatePassword: function(password) {
      var passWordReg = /^((?=.*[a-zA-Z])(?=.*\d)(?:[0-9a-zA-Z.@-]){8,18})$/;

      return passWordReg.test(password);
    },

    validateMatchingPasswords: function(password) {
      return password === $('#password').val();
    },

    validatePhoneNumber: function(phoneNumber) {
      var phoneNumberRegExp = /^(07\d{8}|(00|\+)?46\d{9})$/;

      phoneNumber = phoneNumber.replace(/-|\s/g, '');

      return phoneNumberRegExp.test(phoneNumber);
    },

    validateHtmlFree: function(text) {
      var htmlFreeRegexp = /(<([^>]+)>)/i;

      return !htmlFreeRegexp.test(text);
    },

    validateUrlFree: function(text) {
      var htmlFreeRegexp = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/i;

      return !htmlFreeRegexp.test(text);
    },

    validateNonValidChar: function(text) {
      var nonValidRegexp = /[<>%]/g;

      return !nonValidRegexp.test(text);
    },
    validateTrimmed: {
      callback: (text) => String(text) === String(text).trim(),
      defaultMessage: 'Fältet får inte inledas eller avslutas med mellanslag.'
    },

    validateNoConsecutiveSpaces: {
      callback: (text) => !text.includes('  '),
      defaultMessage: 'Fältet får inte innehålla fler än ett mellanslag på rad.'
    },

    validateIsoPattern: function(text) {
      var iso88591Pattern = '[^\x00-\xFF]';

      var iso88591RegExp = new RegExp(iso88591Pattern);

      if (iso88591RegExp.test(text)) {
        return false;
      }

      return true;
    },

    validateTipssmMessage: function(text) {
      var iso88591Pattern = '[^\x00-\xFF]';

      var specialCharsPattern = '[<>%:@]';

      var iso88591RegExp = new RegExp(iso88591Pattern);
      var specialCharsRegExp = new RegExp(specialCharsPattern);

      if (iso88591RegExp.test(text)) {
        return false;
      }

      if (specialCharsRegExp.test(text)) {
        return false;
      }

      return true;
    },

    validateCustomerId: function(customerId) {
      var customerIdRegExp = /^\d+$/;

      return customerIdRegExp.test(customerId);
    },
    validateOnlyDigits: function(data) {
      return /^\d+$/.test(data);
    },

    validateCurrencyWithCents: function(data) {
      return /(^\d+)((,{1}\d{1}0)|)$/.test(data);
    },

    validateCurrencyWithOneOrTwoDecimals: function(data) {
      return /(^\d+)((,{1}\d{1}\d?)|)$/.test(data);
    },

    validateRangeWithCents: function(value, element) {
      var min = parseFloat(element.data('min').toString()
        .replace(',', '.'));
      var max = parseFloat(element.data('max').toString()
        .replace(',', '.'));
      value = parseFloat(value.replace(',', '.'));

      if (isNaN(max)) {
        return value >= min;
      }

      if (isNaN(min)) {
        return value <= max;
      }

      return value >= min && value <= max;
    },

    validateControlAnswer: function(answer) {
      var controlAnswerReg = /^(?:[0-9a-zåäöA-ZÅÄÖ.@\-\s]){3,40}$/;

      return controlAnswerReg.test(answer);
    },

    validateVoucherCode: function(str) {
      str = str.replace(/ /g, '');
      return str.length === 12;
    },

    validateMin: function(value, element) {
      var min = parseInt(element.data('min'), 10);
      value = parseInt(value, 10);

      return value >= min;
    },

    validateDataMin: function(value, element) {
      var min = parseInt(element.data('min'), 10);
      value = parseInt($(element).attr('data-value'), 10);

      return value >= min;
    },

    validateMax: function(value, element) {
      var max = parseInt(element.data('max'), 10);
      value = parseInt(value, 10);

      return value <= max;
    },

    validateDataMax: function(value, element) {
      var max = parseInt(element.data('max'), 10);
      value = parseInt($(element).attr('data-value'), 10);

      return value <= max;
    },

    validateRange: function(value, element) {
      var min = parseInt(element.data('min'), 10);
      var max = parseInt(element.data('max'), 10);
      value = parseInt(value, 10);

      if (isNaN(max)) {
        return value >= min;
      }

      if (isNaN(min)) {
        return value <= max;
      }

      return value >= min && value <= max;
    },

    validateAlphanumericLength: function(value, element) {
      return value.length <= parseInt(element.data('max'), 10);
    },

    validateMaxLength: function(value, element) {
      return value.length <= parseInt(element.attr('maxLength'), 10);
    },

    validateSyndicateName: function(value) {
      return /^[A-Za-zÅÄÖåäö0-9_\s-]{2,31}$/.test(value) && Boolean(value.trim());
    },
    validateRadioButton: function(value, element) {
      return $('[name="' + $(element).attr('name') + '"]:checked').length > 0;
    },
    validateDate: function(date) {
      return /^(\d{2}|\d{4})-\d{2}-\d{2}$/.test(date);
    },

    validateTime: function(time) {
      return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
    },
    validateUrl: function(url) {
      return /^(ftp|http|https):\/\/[^ "]+$/.test(url);
    },

    validateEmailOrPhoneNumber: function(data) {
      if (this.validatePhoneNumber(data) || this.validateEmail(data)) {
        return true;
      }
      return false;
    },

    showErrorMessage: function(id, messageObject) {
      showError(
        $('#' + id),
        messageObject.message,
        messageObject.group || null
      );
    },

    hideErrorMessage: function(id) {
      resetError($('#' + id));
    },
    validateFields: function(fields, shouldValidateHidden) {
      var successful = true;
      for (var i = 0; i < fields.length; i++) {
        var element = $('#' + fields[i]);
        if (
          svs.components.formValidation.validateField(
            element,
            shouldValidateHidden
          ) === false
        ) {
          successful = false;
        }
      }

      return successful;
    },

    customValueValidation(elements, validators, errorMessage) {
      if (!Array.isArray(elements)) {
        elements = [elements];
      }

      if (!Array.isArray(validators)) {
        validators = [validators];
      }

      const validationResults = [];

      validators.forEach((validator) => {
        if (typeof validator === 'object') {
          errorMessage = errorMessage || validator.defaultMessage;
          validator = validator.callback;
        }

        elements.forEach((element) => {
          element = $(elements);
          const isValid = validator(element.val());
          !isValid && showError(element, errorMessage);
          validationResults.push(isValid);
        });
      });

      return validationResults.every(Boolean);
    },

    validateRequiredFields: function(
      optFields,
      optDoNotshowErrorMessages,
      shouldValidateHidden,
      customValidation
    ) {
      if (
        optFields &&
        !optDoNotshowErrorMessages &&
        !(optFields instanceof Array)
      ) {
        optDoNotshowErrorMessages = optFields;
        optFields = undefined;
      }
      var fields;
      var successful = true;
      if (!optFields) {
        fields = $('.js-input[data-required]');
      } else {
        fields = $('#' + optFields.join(',#'));
      }

      fields.each(function() {
        var element = $(this);

        if (element.hasClass('js-select') && !element.is('select')) {
          element = element.find('select');
        }

        if ($(element).is(':visible') || shouldValidateHidden) {
          if (
            $(element).attr('type') === 'radio' ||
            (customValidation ? customValidation($(element)) : false)
          ) {
            if (
              $('[name="' + $(element).attr('name') + '"]:checked').length ===
              0
            ) {
              successful = false;
              if (!optDoNotshowErrorMessages) {
                showError(element, element.data('required'));
              }
            }
          } else if (!element.val()) {
            successful = false;
            if (!optDoNotshowErrorMessages) {
              showError(element, element.data('required'));
            }
          }
        }

        if ($(element).is(':hidden') && shouldValidateHidden) {
          if (!element.val()) {
            showError(element, element.data('required'));
          }
        }
      });
      return successful;
    },

    validateMinimumRequiredInput: function(fields, minimumChars) {
      var fieldCount = fields.length;
      var validFieldCount = 0;
      if (!fields) {
        fields = $('.js-input[data-required]');
      } else {
        fields = $('#' + fields.join(',#'));
      }

      fields.each(function() {
        var element = $(this);

        if (element.hasClass('js-select') && !element.is('select')) {
          element = element.find('select');
        }
        if ($(element).is(':visible') && element.val()) {
          if (element.val().length >= minimumChars) {
            validFieldCount++;
          }
        }
      });
      return validFieldCount === fieldCount;
    },

    validateField: function(element, shouldValidateHidden) {
      if (!shouldValidateHidden && $(element).is(':hidden')) {
        return true;
      }

      if (element.hasClass('js-select') && !element.is('select')) {
        element = element.find('select');
      }

      var d = element.data();
      var successful = true;

      if (element.val() === '') {
        resetError(element);
      } else {
        for (var prop in d) {
          if (Object.prototype.hasOwnProperty.call(d, prop)) {
            if (
              svs.components.formValidation[prop] &&
              !svs.components.formValidation[prop](element.val(), element)
            ) {
              successful = false;
              showError(element, d[prop]);
            }
          }
        }

        if (successful) {
          showSuccess(element);
        }
      }

      return successful;
    },

    validateAll: function(shouldValidateHidden) {
      var successful = true;
      $('.js-input').each(function() {
        if (
          svs.components.formValidation.validateField(
            $(this),
            shouldValidateHidden
          ) === false
        ) {
          successful = false;
        }
      });

      return successful;
    },

    validatePlaceholderFields: function(callback, content, placeholders) {
      var successful = true;
      const { isValid } = callback(content, placeholders);
      $('.js-input-placeholder').each(function() {
        if (isValid === false) {
          successful = false;
          showError($(this));
        }
      });

      if (successful) {
        showSuccess($(this));
      }
      return successful;
    },

    resetAll: function() {
      resetError($(this));
    },

    _onFocus: function($target) {
      var thisControlGroup = $target.parents('.control-group');
      thisControlGroup.addClass('focus');

      if (thisControlGroup.hasClass('error')) {
        thisControlGroup.addClass('was-error');
      }
      if (!thisControlGroup.hasClass('form-v2')) {
        thisControlGroup.removeClass('error');
      }
      thisControlGroup.find('.help-message').css('display', 'inline-block');
    },

    _onChange: function($target) {
      $target
        .parents('.control-group')
        .removeClass('was-error')
        .removeClass('success');
    },

    _onBlur: function($target) {
      var thisControlGroup = $target.parents('.control-group');

      thisControlGroup
        .removeClass('focus')
        .find('.help-message')
        .css('display', 'none');

      if (thisControlGroup.hasClass('was-error')) {
        thisControlGroup.addClass('error');
        thisControlGroup.removeClass('was-error');
      }

      if (svs.components.formValidation.validateOnBlur) {
        svs.components.formValidation.validateField($target);
      }
    },
    resetValidateOnBlur: function(validateOnBlur) {
      svs.components.formValidation.validateOnBlur = validateOnBlur;
    },

    validateStartAndEndPeriod: function(startDate, endDate, startTime, endTime, startMessage, endMessage) {
      var successful = true;
      const isValid = hasValidStartAndEndPeriod(startDate, endDate, startTime, endTime);
      $('.js-input-period').each(function() {
        if (isValid === false) {
          successful = false;
          showError($('.js-input-start-period'), startMessage, null, true);
          showError($('.js-input-end-period'), endMessage, null, true);
        }
      });

      if (successful) {
        showSuccess($(this));
      }
      return successful;
    },

    validateStartAndEndTime: function(startTime, endTime, startMessage, endMessage) {
      var successful = true;
      const isValid = hasValidStartAndEndTime(startTime, endTime);
      $('.js-input-time').each(function() {
        if (isValid === false) {
          successful = false;
          showError($('.js-input-start-time'), startMessage, null, true);
          showError($('.js-input-end-time'), endMessage, null, true);
        }
      });

      if (successful) {
        showSuccess($(this));
      }
      return successful;
    },

    init: function() {
      var input = $('.js-input');

      input.focus(function(e) {
        svs.components.formValidation._onFocus($(e.target));
      });

      input.change(function(e) {
        svs.components.formValidation._onChange($(e.target));
      });

      input.blur(function(e) {
        svs.components.formValidation._onBlur($(e.target));
      });

      svs.components.formValidation.validateOnBlur =
        svs.core.data.capabilities.formFactor === 'desktop';
    }
  };
})();


 })(window);